#terms-and-conditions header {
    margin: 0 0 20px 0;
}

#terms-and-conditions a {
    color: #4a8fff;
}

#terms-and-conditions a:active {
    color: #3973cc;
}

#terms-and-conditions ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
