#app {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

#app p {
    color: white;
    font-size: 1.2em;
    margin: 0;
}

#app #shop-owner {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#app #shop-owner a div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

#app #shop-owner a div svg {
    height: 24px;
}

#app #shop-owner a, #app #shop-owner a div p {
    color: #4a8fff;
    margin: 0;
}

#app #shop-owner a:active, #app #shop-owner a div p:active {
    color: #3973cc;
}

#app #store-badges {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

#app #store-badges a {
    display: flex;
    width: min(100%, 275px);
    margin: 0;
}

#app #store-badges a img {
    height: auto;
    width: min(100%, 275px);
    margin: 0;
}
