header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    row-gap: 5px;
}

header img {
    height: 75px;
    border-radius: 10px;
    margin: 0;
}

header h1 {
    font-size: 2.5em;
    margin: 0;
}
