footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

footer a:hover {
    background-color: #0009;
}

footer a {
    padding-inline: 10px;
    padding-block: 5px;
    background-color: #0003;
    border-radius: 5px;
    text-decoration: none;
    white-space: nowrap;
    color: #4a8fff;
    margin: 0;
}
