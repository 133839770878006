#legal-notice {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#legal-notice h1 {
    margin: 0;
}

#legal-notice #address {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#legal-notice #address h3 {
    margin: 0 0 5px 0;
}

#legal-notice #address p {
    margin: 0;
}

#legal-notice #contact {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#legal-notice #contact h3 {
    margin: 0;
}

#legal-notice #contact a {
    color: #4a8fff;
}

#legal-notice #contact a:active {
    color: #3973cc;
}

#legal-notice #contact a div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

#legal-notice #contact a div svg {
    height: 20px;
}

#legal-notice #contact a div p {
    margin: 0;
}
